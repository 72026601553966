/* ===========================
Badges
=========================== */

.badge {
    display: inline-flex;
    align-items: center;
    gap: var(--space-1);
    color: var(--white);
    font-weight: var(--font-weight-semibold);
}

.badge--small {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    padding: var(--space-05) var(--space-2);
}

.badge--tip                 { background-color: var(--indigo); }
.badge--available           { background-color: var(--go-green); }
.badge--sale, .badge--gift  { background-color: var(--paradise-pink); }
.badge--new                 { background-color: rgba(var(--sunglow),1); }
.badge--gender              { background-color: var(--gray-10); color: var(--gray-70); }


.badge--gender .icon { 
    flex: 0 0 auto;
    fill: var(--gray-70);
    width: rem(16);
    height: rem(16);
}

.badge--gender .icon-gender-men { 
    width: rem(14);
    height: rem(14); 
}

.badge--gender .icon-gender-women { 
    width: rem(11); 
}

.badge--gender .icon-gender-couple { 
    width: rem(20); 
}