.c-header-promo-bar {
	background-color: var(--gray-90);
	position: relative;
}

.c-header-promo-bar__message {
	padding: var(--space-3) var(--space-12) var(--space-3) var(--space-3);
	margin: 0;
	color: var(--white);
	font-size: var(--font-size-2);
	line-height: var(--line-height-2);
	max-width: 100%;
}

.c-header-promo-bar__message a {
	font-weight: var(--font-semibold);
	color: var(--white);
}

.c-header-promo-bar__countdown {
	display: inline-block;
	width: rem(120);
	
	@include breakpoint(min-640) {
		margin-left: rem(16);
	}
}

.c-header-promo-bar__close-btn {
	background: transparent;
	border: none;
	margin-left: auto;
	transition: fill .2s $ease-in-out-quad;
	width: rem(16);
	height: rem(16);
	position: absolute;
	right: rem(16);
	top: 50%;
	margin-top: - rem(8);
}

.c-header-promo-bar__close-btn .icon {
	width: rem(16);
	height: rem(16);
	fill: var(--gray-50);
}

.c-header-promo-bar__close-btn:hover .icon {
	fill: var(--white);
}

.c-header-promo-bar--cart-info {
	background-color: #F8D447;
}

.c-header-promo-bar--cart-info .c-header-promo-bar__message {
	color: var(--gray-70);
	display: flex;
	gap: var(--space-2);
}

.c-header-promo-bar--cart-info .c-header-promo-bar__message p {
	font-size: var(--font-size-2);
	line-height: var(--line-height-2);
	max-width: 100%;
	margin: 0;
}

.c-header-promo-bar--cart-info .c-header-promo-bar__message b {
	display: block;
}

.c-header-promo-bar--cart-info .c-header-promo-bar__message a {
	color: var(--gray-90);
}

.c-header-promo-bar--cart-info .icon {
	width: rem(16);
	flex: 0 0 auto;
	fill: var(--gray-70);
}