/* ===========================
Breadcrumbs
=========================== */

.c-breadcrumbs {
	width: 100%;
	list-style-type: none;
	padding: var(--space-2) 0;
	margin: 0;
	display: flex;
	white-space: nowrap;
	overflow-x: auto;
  
	@include breakpoint(min-640) {
		padding: var(--space-6) 0;
	}
}

.c-breadcrumbs__item {
	flex: 0 0 auto;
	font-size: var(--font-size-2);
	line-height: var(--line-height-2);
}

.c-breadcrumbs__item::after {
	content: "/";
	font-weight: var(--font-weight-regular);
	margin: 0 var(--space-1);
	color: var(--gray-70);
	opacity: .3;
}

.c-breadcrumbs__item:last-child::after {
	display: none;
}

.c-breadcrumbs__link {
	color: var(--gray-70);
	font-weight: var(--font-weight-regular);
	text-decoration-line: none;
	text-decoration-color: transparent;
	transition: text-decoration-color .2s $ease-in-out-quad, color .2s $ease-in-out-quad;
}

.c-breadcrumbs__link:hover {
	color: var(--gray-90);
	text-decoration-line: underline;
	text-decoration-style: solid;
	text-decoration-color: var(--gray-90);
}

@supports (text-decoration-thickness: var(--space-05)) and (text-underline-offset: var(--space-1)) {
	.c-breadcrumbs__link:hover {
		text-decoration-thickness: var(--space-05);
		text-underline-offset: var(--space-1);
	}
}

// Centering breadcrumbs in specific sites
.c-category-cover .c-breadcrumbs, .c-cover-image-fullscreen--center .c-breadcrumbs, .collection-cover .c-breadcrumbs {
    justify-content: center;
}

// Gray background in product detail
.l-breadcrumbs-background {
	background-color: var(--image-background-color);

	@include breakpoint(min-960) {
		background-color: transparent;
	}

}