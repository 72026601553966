/* ===========================
Component / Search bar
=========================== */

.c-search-bar {}
    
.c-search-bar__form {
    display: flex;
}

.c-search-bar__label {
    position: relative;
    flex: 1 1 100%;

    @include breakpoint(min-960) {
        flex: 0 0 calc(100% - 4rem);
    }
}

.c-search-bar .icon-search {
    position: absolute;
    width: rem(20);
    height: rem(20);
    left: var(--space-3);
    top: 50%;
    margin-top: - rem(10);
    fill: var(--gray-40);
}

.c-search-bar__input {
    font-size: var(--font-size-4);
    line-height: var(--line-height-7);
    padding: 0 var(--space-10);
    border: none;
    color: var(--gray-90);
    border-radius: rem(44);
    background-color: var(--gray-10);
    margin-right: var(--space-2);
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
}

.c-search-bar__input::placeholder {
    color: var(--gray-70);
}

.c-search-bar__input:focus {
    box-shadow: inset 0 0 0 rem(2) var(--gray-50);
    background-color: var(--white);
    outline: none;
}

.c-search-bar__close-btn {
    border: none;
    display: block;
    padding: var(--space-3);
    border-radius: rem(44);
    position: absolute;
    top: 50%;
    margin-top: calc(var(--space-3) * -1);
    right: var(--space-4);
}

.c-search-bar__close-btn[hidden] {
    display: none;
}

.c-search-bar__search-btn {
    display: block;
    position: absolute;
    top: 50%;
    right: var(--space-1);
    margin-top: calc(var(--space-5) * -1);
}

.c-search-bar__search-btn[hidden] {
    display: none;
}

.c-search-bar .icon-cross {
    position: absolute;
    width: rem(12);
    height: rem(12);
    right: var(--space-2);
    top: 50%;
    margin-top: calc(var(--space-2) * -1);
    fill: var(--gray-40);
}

.mobile .c-search-bar__search-btn[hidden] {
    display: block;
}