/* ===========================
Buttons
=========================== */

:root {
    --btn-font-size: var(--font-size-2);
    --btn-font-size-small: var(--font-size-1);

    --btn-line-height: var(--line-height-3);
    --btn-line-height-small: var(--line-height-2);

    --btn-icon-size-large: 1.25rem;
    --btn-icon-size-medium: 1rem;
    --btn-icon-size-small: 0.875rem;
    --btn-icon-size-xsmall: 0.75rem;
}

@include breakpoint (min-768) {
    
    :root {
        --btn-font-size: var(--font-size-3);
        --btn-font-size-small: var(--font-size-2);

        --btn-line-height: 1.375rem;
        --btn-line-height-small: 1.25rem;
    }   
}

.btn {
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--font-weight-semibold);    
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    border: none;
    text-decoration: none;
    transition: background-color .2s $ease-in-out-quad, color .2s $ease-in-out-quad, border-color .2s $ease-in-out-quad;
}

.btn--fullwidth {
    width: 100%;
}

.btn--rounded {
    border-radius: 100%;
}

// Types
.btn--primary {
    background-color: var(--gray-90);
    color: var(--white);
    border: rem(1) solid var(--gray-90);
}

    .btn--primary:hover {
        background-color: var(--black);
    }

    .btn--primary .icon {
        fill: var(--white);
    }

.btn--secondary {
    background-color: var(--white);
    color: var(--gray-90);
    border: rem(2) solid var(--gray-90);
}

.btn--secondary:hover {
    background-color: var(--gray-90);
    color: var(--white);
}

.btn--secondary .icon {
    fill: var(--gray-90);
}

.btn--secondary:hover .icon {
    fill: var(--white);
}


.btn--white {
    background-color: var(--white);
    color: var(--gray-90);
    box-shadow: var(--box-shadow-small);
}

.btn--white:hover {
    background-color: var(--gray-90);
    color: var(--white);
}

.btn--white .icon {
    fill: var(--gray-90);
}

.btn--white:hover .icon {
    fill: var(--white);
}

.danfil-kids .btn--primary,
.btn--kids-primary {
    border: rem(2) solid var(--pink-70);
    background-color: var(--pink-70);
    color: var(--white);
}

.danfil-kids .btn--primary:hover,
.btn--kids-primary:hover {
    background-color: var(--pink-80, #71232A);
    border-color: var(--pink-80, #71232A);
}


.danfil-kids .btn--secondary,
.btn--kids-secondary {
    background-color: var(--white);
    color: var(--pink-70);
    border: rem(2) solid var(--pink-70);
}

.danfil-kids .btn--secondary .icon,
.btn--kids-secondary .icon {
    fill: var(--pink-70);
}

.danfil-kids .btn--secondary:hover,
.btn--kids-secondary:hover {
    background-color: var(--pink-70);
    color: var(--white);
}

.danfil-kids .btn--secondary:hover .icon,
.btn--kids-secondary:hover .icon {
    fill: var(--white);
}

.btn--favorite {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: var(--white);
    width: rem(40);
    height: rem(40);
    transition: box-shadow .2s $ease-in-out-quad;
}

.btn--favorite:hover, .btn--favorite:focus {
    box-shadow: var(--box-shadow-small);
}

.btn--favorite .icon {
    width: var(--btn-medium-icon-size, rem(16));
    height: var(--btn-medium-icon-size, rem(16));
}

// Sizes
.btn--large {
    font-size: var(--btn-font-size, rem(14));
    line-height: var(--btn-line-height);
    padding: var(--space-4) var(--space-6);

    @include breakpoint(min-768) {
        padding: var(--space-5) var(--space-8);
    }
}   

    .btn--large .icon {
        width: var(--btn-large-icon-size, rem(20));
        height: var(--btn-large-icon-size, rem(20));
        margin-right: var(--space-4);
    }

.btn--large.btn--icononly {
    padding: var(--space-5);

    @include breakpoint(min-768) {
        padding: var(--space-4);
    }
}

.btn--large.btn--rounded {
    border-radius: calc(var(--btn-line-height) + (2 * var(--space-5)));
}


.btn--medium {
    font-size: var(--btn-font-size, rem(13));
    line-height: var(--btn-line-height);
    padding: var(--space-3) var(--space-5);
}

.btn--medium.btn--icononly {
    padding: var(--space-3);
}

.btn--medium.btn--rounded {
    border-radius: calc(var(--btn-line-height) + (2 * var(--space-4)));
}

    .btn--medium .icon {
        left: rem(16);
        width: var(--btn-medium-icon-size, rem(20));
        height: var(--btn-medium-icon-size, rem(20));
        margin-right: var(--space-3);
    }

.btn--small {
    font-size: var(--btn-font-size-small);
    line-height: var(--btn-line-height-small);
    padding: var(--space-3) var(--space-5);
}

.btn--small.btn--rounded {
    border-radius: calc(var(--btn-line-height-small) + (2 * var(--space-3)));
}

    .btn--small .icon {
        width: rem(12);
        height: rem(12);
        margin-right: var(--space-2);
    }

.btn--xsmall {
    font-size: var(--btn-font-size-small);
    line-height: var(--btn-line-height-small);
    padding: var(--space-2) var(--space-4);
}

    .btn--xsmall.btn--icononly {
        padding: var(--space-3);
    }

.btn--xsmall.btn--rounded {
    border-radius: calc(var(--btn-line-height-small) + (2 * var(--space-2)));
}

    .btn--xsmall .icon {
        width: rem(12);
        height: rem(12);
        margin-right: var(--space-2);
    }

    .btn--large.btn--icononly .icon,  .btn--medium.btn--icononly .icon,  .btn--small.btn--icononly .icon, .btn--xsmall.btn--icononly .icon {
        margin-right: 0;
    }

// Social buttons 
.btn--facebook {
    background-color: var(--facebook-color, #3B5998);
    color: var(--white);
}

    .btn--facebook:hover,
    .btn--facebook:focus {
        background-color: var(--white);
        color: var(--facebook-color, #3B5998);
    }

    .btn--facebook .icon {
        fill: var(--white);
    }

    .btn--facebook:hover .icon,
    .btn--facebook:focus .icon {
        fill: var(--facebook-color, #3B5998);
    }

    .btn--facebook:focus {
        outline: none;
        box-shadow: 0 0 0 rem(4) rgba(var(--facebook-color, #3B5998), 0.2);
    }


.btn--google {
    background-color: var(--white);
    box-shadow: 0 rem(3) rem(4) 0 rgba(0, 0, 0, .2);
    color: var(--gray-70);
    transition: all 400ms ease 0s;
}

    .btn--google:hover {
        box-shadow: 0 rem(3) rem(8) rgba(117, 117, 117, .5);
    }

    .btn--google:active {
        outline: var(--pink-70) solid rem(2);
    }

    .btn--google:focus {
        outline: var(--pink-70) solid rem(2);
    }



.btn-link {
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    background-color: transparent;
    display: inline;
}

.btn-link--small {
    font-size: var(--font-size-1);
    line-height: rem(18);
    padding: rem(4) 0;
    box-shadow: 0 rem(2) 0 0 var(--gray-90);
    letter-spacing: rem(1);

    @include breakpoint(min-768) {
        font-size: var(--font-size-2);
        line-height: var(--line-height-2);
    }
}


.loading {
    pointer-events: none;
}

.loading::after {
	content: '';
	display: block;
	position: absolute;
	animation: loader-rotate 1s linear infinite;
	border-radius: 50%;
	top: 50%;
}

.btn--primary.loading::after {
	border: rem(3) solid rgba(255, 255, 255, 0.20);
    border-top-color: var(--white);
}

.btn--primary-inverted.loading:after,
.btn--secondary.loading:after {
	border: rem(3) solid rgba(var(--pink-90), 0.2);
    border-top-color: var(--pink-90);
}

.btn--large.loading::after {
	width: rem(20);
    height: rem(20);
	margin-top: - rem(12);
	left: 50%;
    margin-left: - rem(10);
    position: absolute;
}

.btn--medium.loading::after {
	width: rem(16);
    height: rem(16);
	margin-top: - rem(10);
	left: rem(10);
}

.btn--small.loading::after {
	width: rem(12);
    height: rem(12);
	margin-top: - rem(8);
	left: rem(8);
}