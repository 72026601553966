/* ===========================
Certificates
=========================== */

.l-trust-box {
	background-color: var(--white);
	padding: rem(16) 0;
	display: flex;
	flex-direction: column;

	@include breakpoint(min-1024) {
		padding: rem(24) 0;
		flex-direction: row;
	}
}

.c-trust-box--certificates {
	margin-bottom: rem(40);

	@include breakpoint(min-1024) {
		width: 50%;
		margin-bottom: 0;
		padding-right: rem(32);
	}
}

.c-trust-box--payments {
	@include breakpoint(min-1024) {
		width: 50%;
		padding-left: rem(32);
	}
}

.c-trust-box__header {
	display: none;

	@include breakpoint(min-768) {
		display: block;
		color: var(--gray-50);
		font-weight: var(--font-weight-semibold);
		font-size: var(--font-size-3);
		line-height: rem(16);
		margin-bottom: rem(14);
		text-align: center;
	}

	@include breakpoint(min-1024) {
		text-align: left;
	}
}

.c-trust-box__list {
	list-style-type: none;
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
}

.c-trust-box__list-item {
	flex: 1 1 rem(120);
	align-self: center;
	padding: 0 rem(8);
}

.c-trust-box img {
	max-width: 100%;
	display: block;
	margin: 0 auto;
}

.c-trust-box svg {
	max-width: rem(120);
	max-height: rem(50);
	display: block;
	margin: 0 auto;
	width: auto;
	height: auto;

	@include breakpoint(min-1024) {
		margin: 0;
	}
}
