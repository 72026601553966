/* ===========================
Footer Component
=========================== */

.c-footer {
	background-color: var(--gray-90);
	padding-top: rem(40);
	
	@include breakpoint(min-768) {
		padding-top: rem(60);
	}
}

.c-footer > .u-page-wrap {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		
	@include breakpoint(min-1024) {
		flex-direction: row;
	}
}

.c-footer h3 {
	color: var(--gray-50);
	font-weight: var(--font-weight-semibold);
	text-transform: uppercase;
}

.c-footer__contact {
	margin-bottom: rem(36);

	@include breakpoint(min-1024) {
		flex: 0 0 35%;
		margin-bottom: rem(52);
	}
}

.c-footer__contact h3 {
	text-align: center;
	margin-bottom: rem(4);

	@include breakpoint(min-1024) {
		text-align: left;
	}
}
	
.c-footer__contact p {
	font-size: var(--font-size-3);
	line-height: rem(16);
	color: var(--gray-50);
	text-align: center;
	margin: 0 auto rem(16);

	@include breakpoint(min-1024) {
		text-align: left;
	}
}

.c-footer__contact ul {
	font-size: var(--font-size-3);
	line-height: rem(16);
	color: var(--gray-50);
	text-align: center;
	margin: 0 auto rem(16);

	@include breakpoint(min-1024) {
		text-align: left;
	}
}

.c-footer__contact li {
	line-height: rem(24);
}
	
		

.c-footer__contact-list {
	list-style-type: none;
	margin: 0 0 rem(32) 0;
	padding: 0;
	display: flex;		
	flex-direction: column;
	justify-content: center;

	@include breakpoint(min-480) {
		flex-direction: row;
	}
	
	@include breakpoint(min-1024) {
		justify-content: flex-start;
	}
}

.c-footer__contact-list li {
	width: 100%;
	padding: rem(8) rem(16);
	text-align: center;

	@include breakpoint(min-480) {
		&:first-child 	{ text-align: right; }
		&:last-child 	{ text-align: left; }
	}

	@include breakpoint(min-1024) {
		&:first-child 	{ text-align: left; }
	}
}

.c-footer__contact-list a {
	display: inline-block;
	color: var(--white);
	font-weight: var(--font-weight-semibold);
	line-height: rem(20);
	text-decoration: none;
	position: relative;
	padding-left: rem(32);
	
	@include breakpoint(min-1600) {
		font-size: var(--font-size-6);
		line-height: rem(20);
	}
}
	
.c-footer__contact-list a:hover {
	text-decoration: underline;
}

.c-footer__contact-list .icon {
	fill: var(--white);
	width: rem(20);
	height: rem(20);
	top: 0;
	left: 0;
	position: absolute;
}

.c-footer__contact-list span {
	color: var(--white);
	display: block;
}

.c-footer__socials {
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include breakpoint(min-768) {
		margin: 0 - rem(20);
		flex-direction: row;
	}

	@include breakpoint(min-1024) {
		justify-content: flex-start;
	}
}

.c-footer__socials > div {
	@include breakpoint(min-768) {
		margin: 0 rem(20);
	}
}

.c-footer__contact-socials-list {
	display: flex;
	list-style-type: none;
	margin: 0 - rem(8) rem(40);
	padding: 0 0 rem(32) 0;
	justify-content: center;

	@include breakpoint(min-1024) {
		justify-content: flex-start;
		padding: 0;
	}
}

.c-footer__contact-socials-list li {
	margin: 0 rem(8);
}

.c-footer__contact-socials-list a {
	display: block;
	padding: rem(8);
}    

.c-footer__contact-socials-list .icon {
	display: block;
	width: rem(20);
	height: rem(20);
	fill: var(--white);
	transition: fill .2s $ease-in-out-quad;
}

.c-footer__contact-socials-list a:hover .icon {
	fill: rgba(var(--white), 0.9); 
}


.c-footer__other-projects-list {
	display: flex;
	list-style-type: none;
	margin: 0 - rem(8);
	padding: 0 0 rem(32) 0;
	flex-wrap: wrap;
	justify-content: center;

	@include breakpoint(min-1024) {
		justify-content: flex-start;
		padding: 0;
	}
}

.c-footer__other-projects-list li {
	margin: 0 rem(8);
}

.c-footer__other-projects-list a {
	display: block;
	padding: rem(8);
}    

.c-footer__other-projects-list .brilianty-logo,
.c-footer__other-projects-list .danfil-logo,
.c-footer__other-projects-list .detske-ousko-logo,
.c-footer__other-projects-list .danfil-wedding-rings-logo {
	display: block;
	max-width: rem(140);
	height: rem(80);
	fill: var(--white);
	transition: fill .2s $ease-in-out-quad;
}

.c-footer__other-projects-list a:hover .icon {
	fill: rgba(var(--white), 0.9); 
}


.c-footer__sitemap {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: rem(20);
	border-bottom: rem(1) solid var(--gray-70);

	@include breakpoint(min-1024) {
		flex: 0 0 65%;
		border-bottom: none;
		order: -1;
	}
}
	
.c-footer__sitemap ul {
	margin: 0 0 rem(36) 0;
	padding: 0;
	list-style-type: none;
	flex: 1 0 rem(200);

	@include breakpoint(min-480) {
		min-width: rem(220);
	}

	@include breakpoint(min-640) {
		min-width: rem(170);
	}
}
	
.c-footer__sitemap h3 {
	margin-bottom: rem(16);
}
	
.c-footer__sitemap a {
	text-decoration: none;
	color: var(--gray-50);
}
	
.c-footer__sitemap a:hover {
	text-decoration: underline;
}
	
.c-footer__sitemap li > a {
	padding-left: rem(20);
	color: var(--white);
	text-decoration: none;
	font-size: var(--font-size-2);
	font-weight: var(--font-regular, 400);
	line-height: rem(32);
	position: relative;
}
	
.c-footer__sitemap li > a::before {
	content: "";
	display: block;
	width: rem(8);
	height: rem(1);
	position: absolute;
	left: 0;
	top: rem(6);
	background-color: var(--gray-70);
}
	
.c-footer__sitemap li > a:hover {
	text-decoration: underline;
}
		

.c-footer__bottom-row {
	padding-top: rem(20);
	
	@include breakpoint(min-1024) {
		border-top: rem(1) solid var(--gray-70);
		display: flex;
		width: 100%;
	}
}

.c-footer__bottom-links {
	list-style-type: none;
	margin: 0 0 rem(60) 0;
	padding: 0;
	text-align: center;

	@include breakpoint(min-1024) {
		margin-bottom: 0;
	}
}
	
.c-footer__bottom-links li {
	padding: rem(8);
	line-height: rem(16);

	@include breakpoint(min-480) {
		display: inline-block;
		padding: rem(8) 0;
		margin-right: rem(8);
	}
}
	
.c-footer__bottom-links li:last-child {
	margin-right: 0;
}
	
.c-footer__bottom-links a {
	font-size: var(--font-size-2);
	color: var(--gray-50);
	text-decoration: none;
}
	
.c-footer__bottom-links a:hover {
	text-decoration: underline;
}
	
.c-footer__copyright {
	text-align: center;
	color: var(--gray-50);
	font-size: var(--font-size-2);
	line-height: rem(16); 	
	margin: 0 auto rem(32);

	@include breakpoint(min-1024) {
		margin: rem(8) 0 rem(8) auto;
	}
}
	
.c-footer__eet {
	background: var(--black);
	display: block;
	text-align: center;
	color: var(--gray-50);
	font-size: var(--font-size-2);
	line-height: rem(16); 
	padding: rem(16);
	margin: 0;
	max-width: 100%;
}

.footer-language-switch {
	margin-bottom: rem(20);
	position: relative;

	@include breakpoint(min-1024) {
		margin-right: rem(16);
	}

	.icon-flag {
		width: rem(16);
		height: rem(12);
		position: absolute;
		top: rem(10);
		left: rem(8);
	}

	.icon-chevron-bottom {
		fill: var(--gray-50);
		width: rem(8);
		height: rem(8);
		position: absolute;
		right: rem(8);
		top: rem(12);
	}

	&__button {
		width: 100%;
		border: none;
		background-color: var(--gray-80);
		border-radius: rem(2);
		padding: rem(8) 0 rem(8) rem(36);
		position: relative;
		color: var(--white);
		font-size: var(--font-size-2);
		line-height: rem(16);
		text-transform: uppercase;
		font-weight: var(--font-weight-semibold);
		text-align: left;
		transition: background-color .2s $ease-in-out-quad;
		display: block;

		&:hover {
			background-color: var(--gray-70);
		}

		@include breakpoint(min-480) {
			width: auto;
			padding: rem(8) rem(24) rem(8) rem(36);
			margin: 0 auto;
		}
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style-type: none;
		bottom: calc(100% + 4px);
		width: 100%;
		border-radius: rem(2);
		z-index: $z-index-10;
		background-color: var(--gray-80);

		@include breakpoint(min-480) {
			position: absolute;
		}
	}

	&__list-item {
		transition: background-color .2s $ease-in-out-quad;

		a {
			display: block;
			color: var(--white);
			padding: rem(8) 0 rem(8) rem(36);
			font-size: var(--font-size-2);
			line-height: rem(16);
			text-transform: uppercase;
			font-weight: var(--font-weight-semibold);
			text-align: left;
			text-decoration: none;
		}

		&:hover {
			background-color: var(--gray-70);
		}
	}

}