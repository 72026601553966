/* ---------------------------------------------------------------------------------------------------------- 
Shame CSS - need to be repaired  ----------------------------------------------------------------------------
---------------------------------------------------------------------------------------------------------- */

#chat-application {
	z-index: $z-index-11 !important;
}

.simpleTabsContent {
	display: none;
}
.simpleTabsContent.currentTab {
	display: block;
}

.order-summary {
	display: flex;
	width: 100%;
	flex-direction: column;
	flex-wrap: wrap;
	margin-bottom: rem(20);

	@include breakpoint(min-640) {
		flex-direction: row;
	}

	> div {
		width: 100%;

		@include breakpoint(min-640) {
			width: 50%;
		}
		@include breakpoint(min-960) {
			width: 25%;
		}
	}

	ul {
		list-style-type: none;
		padding: 0;
	}
}

.danfil-promo {
	padding: rem(60) 0 !important;

	h2 {
		max-width: 75ch;
		margin: 0 auto rem(20) auto !important;
		text-align: center;
	}

	p {
		max-width: 75ch;
		margin: 0 auto rem(32) auto !important;
		text-align: center;
	}

	.btn {
		width: 100%;
		max-width: rem(260);
		margin: rem(40) auto 0 auto;
	}
}

.grandchild-phone {
	display: block;
	font-size: var(--font-size-9);
	line-height: rem(48);
}

/*
    POPUP style override
 */
.swal2-html-container {
	text-align: left;
	padding: 2.5rem;
	margin: 0;
}

/*
    Photoswipe style override
*/
.pswp {
	--pswp-bg: #fff !important;
}

.pswp__counter {
	text-shadow: none !important;
	color: var(--gray-70) !important;
}

.pswp__icn {
	fill: var(--gray-50) !important;
	color: var(--white) !important;
	--pswp-icon-stroke-width: 0px !important;
}

.pswp__custom-video-container {
	position: relative;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.pswp__custom-video-container iframe {
	background: #444;
	width: 100%;
	height: 100%;
	max-width: 800px;
	max-height: 600px;
	pointer-events: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.christmas-2022-phase-4 .h3,
.christmas-2022-phase-4 .p-small {
	color: var(--white);
}

.christmas-2022-cover .c-cover-image-fullscreen__heading,
.christmas-2022-cover .c-cover-image-fullscreen__desc {
	@include breakpoint(min-768) {
		color: var(--white);
	}
}

.valentine-2023 .h3 {
	display: block;
	max-width: 12ch;
	font-size: var(--font-size-9);
	line-height: var(--line-height-7);
}


.test-css-class {
	background: beige;
}

.footer-marianne-days-link {
	color: white;
	font-size: var(--font-size-1);
	display: flex;
	align-items: center;
}

.footer-marianne-days-link picture {
	display: block;
	margin-left: var(--space-2);
}

.marianne-2023 figcaption {
	top: rem(80);
}
